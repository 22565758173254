import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  yearlyUpdateId,
  Payment,
  stateSliceTypes,
  iBbgDataState,
  dateTerms
} from "../../../app/models";
import { RootState } from "../../../app/store";

const initialState: iBbgDataState = {
  requestId: null,
  bbgProgressReportData: {},
  bbgFinalReportData: {},
  hasBbgRequirementRef: "idle",
  hasBbgRequestRef: "idle",
  bbgRequestRef: null,
  payments: [],
  paymentsLoading: "idle"
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchBlackbaudRequestRefAsync = createAsyncThunk(
  "bbgDataState/fetchBlackbaudRequestRefAsync",
  async (id: string) => {
    const response = await fetch(`/api/blackbaud/request/ref/${id}`);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 500) {
      return {
        requests: [],
      };
    }
    return response.json();
  }
);

export const fetchBlackbaudProgressReportRefAsync = createAsyncThunk(
  "bbgDataState/fetchBlackbaudProgressReportRefAsync",
  async (reqId: number) => {
    const response = await fetch(`/api/blackbaud/requirement/ref/${reqId}`);
    // The value we return becomes the `fulfilled` action payload
    // if (response.status === 500) {
    //   return {
    //     requests: [],
    //   };
    // }

    return response.json();

  }
);

export const fetchBlackbaudFinalReportRefAsync = createAsyncThunk(
  "bbgDataState/fetchBlackbaudFinalReportRefAsync",
  async (reqId: number) => {
    const response = await fetch(`/api/blackbaud/requirement/ref/${reqId}`);
    return response.json();
  }
);


export const fetchBlackbaudPaymentRefAsync = createAsyncThunk(
  "bbgDataState/fetchBlackbaudPaymentRefAsync",
  async (reqId: number) => {
    const response = await fetch(`/api/blackbaud/payment/ref/${reqId}`);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 500) {
      return {
        requests: [],
      };
    }
    return response.json();
  }
);



export const bbgDataStateSlice = createSlice({
  name: "bbgData",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlackbaudRequestRefAsync.pending, (state) => {
        state.hasBbgRequestRef = "searching";
        state.bbgRequestRef = {};
      })
      .addCase(fetchBlackbaudRequestRefAsync.rejected, (state) => {
        state.hasBbgRequestRef = "not_found";
        state.bbgRequestRef = {};
      })
      .addCase(fetchBlackbaudRequestRefAsync.fulfilled, (state, action) => {
        if (action.payload?.requests.length === 0) {
          state.hasBbgRequestRef = "not_found";
          state.bbgRequestRef = {};
        } else if (action.payload?.requests.length > 1) {
          state.hasBbgRequestRef = "too_many";
          state.bbgRequestRef = {};
        } else if (action.payload?.requests.length === 1) {
          state.hasBbgRequestRef = "found";
          state.bbgRequestRef = action.payload?.requests[0];
          state.requestId = action.payload?.requests[0].id
        }
      })
      .addCase(fetchBlackbaudProgressReportRefAsync.pending, (state) => {
        state.hasBbgRequirementRef = "searching";
        state.bbgProgressReportData = {};
      })
      .addCase(fetchBlackbaudProgressReportRefAsync.rejected, (state, action) => {
        state.hasBbgRequirementRef = "not_found";
        state.bbgProgressReportData = {
          ...state.bbgProgressReportData,
          [action.meta.arg]: {
            status: "failed",
            dates: { beginning: null, ending: null, nextEndDate: null }
          }
        };
      })
      .addCase(fetchBlackbaudProgressReportRefAsync.fulfilled, (state, action) => {
        const {meta, payload} = action
        console.log(payload, meta)

        if(action.payload.requirement === null){
          console.log("failure to load", action.meta, action.payload)
          state.bbgProgressReportData = {
            ...state.bbgProgressReportData,
            [meta.arg]: {
              status: "failed",
              dates: { beginning: null, ending: null, nextEndDate: null }
            }
          };
        } else {
        const {customFields} = payload.requirement 
        const beginningDate = customFields.reporting_Period_Start_Date
        const endDate = customFields.reporting_Period_Projected_End_Date
        const nextEndDate = customFields.reporting_Period_Next_End_Date
        state.hasBbgRequirementRef = "found";
        state.bbgProgressReportData = {
          ...state.bbgProgressReportData, 
          [payload.requirement.id]: {
            status: "loaded",
            dates: { beginning: beginningDate, ending: endDate, nextEndDate: nextEndDate },
            locked: payload.requirement.receivedDate !== null
          }
        };
      }})
      .addCase(fetchBlackbaudFinalReportRefAsync.pending, (state) => {
        state.bbgFinalReportData = {};
      })
      .addCase(fetchBlackbaudFinalReportRefAsync.rejected, (state, action) => {
        state.hasBbgRequirementRef = "not_found";
        state.bbgFinalReportData = {
            status: "failed",
            dates: { beginning: null, ending: null, nextEndDate: null }
          };
      })
      .addCase(fetchBlackbaudFinalReportRefAsync.fulfilled, (state, action) => {
        const {meta, payload} = action
        console.log(payload, meta)

        if(action.payload.requirement === null){
          console.log("failure to load", action.meta, action.payload)
          state.bbgFinalReportData = {
            status: "failed",
            dates: { beginning: null, ending: null, nextEndDate: null }
          };
        } else {
        const {customFields} = payload.requirement 
        const beginningDate = customFields.reporting_Period_Start_Date
        const endDate = customFields.reporting_Period_Projected_End_Date
        state.bbgFinalReportData = {
            status: "loaded",
            dates: { beginning: beginningDate, ending: endDate }
          }
      }})
      .addCase(fetchBlackbaudPaymentRefAsync.fulfilled, (state, action) => {
        state.payments = action.payload.payments.map( payment => (
          {
            scheduleDate: payment.scheduleDate,
            amount: payment.amount
          }
        ))
        state.paymentsLoading = "loaded"
      }
      )
  },
});

// export const {} = bbgDataStateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

const { BBGSTATE, BUDGETSTATE } = stateSliceTypes

                  /////// COMMON REPORT SELECTORS /////////

export const selectRequestId = (state:RootState) => 
  state[BBGSTATE].requestId

export const selectBbgLoading = (state: RootState) =>
  state[BBGSTATE].bbgRequestRef
export const selectPaymentsLoading = (state: RootState) => 
  state[BBGSTATE].paymentsLoading

export const selectRequestStatus = (state: RootState) =>
  {
    switch(state[BBGSTATE].hasBbgRequestRef){
      case "idle": return 'idle'
      case "found": return 'loaded'
      case "searching": return "loading"
      case "not_found": return "failed"
      default: return "loading"
  }}

export const selectProgressReportKeys = (state: RootState) =>
  state[BUDGETSTATE].progressReports

export const selectRequirementStatus: (
  state: RootState
) => ((progressReportId: number) => "failed" | "loaded" | "loading") = (state: RootState) => {
  return (progressReportId: number) => {
    return state[BBGSTATE].bbgProgressReportData[progressReportId]?.status
  }
};

export const selectProgressReportLocked: (
  state: RootState
) => ((progressReportId: number) => boolean) = (state: RootState) => {
  return (progressReportId: number) => {
    return state[BBGSTATE].bbgProgressReportData[progressReportId]?.locked
  }
};

export const selectAnyProgressReportsStatus = (state: RootState) =>{
    const keys = selectProgressReportKeys(state)
    if ( keys.length === 0){ 
      return "none"
    }
    if (keys.some( item => selectRequirementStatus(state)(item) === "failed" )){
      return "failed"
    }
    if (keys.every( item => selectRequirementStatus(state)(item) === "loaded") && keys.length > 0){
      return "loaded"
    }
    return "loading"
  }

export const selectFinalReportStatus = (state: RootState) => {
  const finalReportId = state[BUDGETSTATE].finalReport
  if (finalReportId === 0 || finalReportId === null){
    console.log("no final report")
    return "none"
  }
  const finalReportStatus = state[BBGSTATE].bbgFinalReportData.status
  if( finalReportStatus === "failed" || finalReportStatus === "loaded"){
    return finalReportStatus
  }
  return "loading"
}

  export const selectGetBeginingOrEndingDates: (
    state: RootState
  ) => ((ProgressReportId: number) => {[yearlyUpdateId: string]: {[dateTerms.beginning]: Date, [dateTerms.ending]: Date} }) = (state: RootState) => {
    return (ProgressReportId: number) => {
      const dateBeginning = state[BBGSTATE].bbgProgressReportData[ProgressReportId]?.dates?.beginning
      const startDate = dateBeginning !== null ? new Date(dateBeginning) : null
      const dateEnding = state[BBGSTATE].bbgProgressReportData[ProgressReportId].dates.ending;
      const endDate = dateEnding !== null ? new Date(dateEnding) : null
      const nextStart = endDate !== null ? new Date(endDate) : null
      endDate !== null && nextStart.setDate(endDate.getDate() + 1)
  
      const nextDateEnd = state[BBGSTATE].bbgProgressReportData[ProgressReportId].dates.nextEndDate
      const nextEnd = nextDateEnd !== null ? new Date(state[BBGSTATE].bbgProgressReportData[ProgressReportId].dates.nextEndDate) : null
      const dateObj = {
        [yearlyUpdateId.current]: {
          [dateTerms.beginning]: startDate,
          [dateTerms.ending]: endDate
        },
        [yearlyUpdateId.actual]: {
          [dateTerms.beginning]: startDate,
          [dateTerms.ending]: new Date(state[BUDGETSTATE].progressReportBudgets[ProgressReportId].actualEndDate + "T00:00:00")
        },
        [yearlyUpdateId.projected]: {
          [dateTerms.beginning]: startDate,
          [dateTerms.ending]: endDate
        },
        [yearlyUpdateId.nextReport]: {
          [dateTerms.beginning]: nextStart,
          [dateTerms.ending]: nextEnd
        }
      }
      return dateObj
    }
  };

export const selectBeginingOrEndingDates: (
  state: RootState
) => ((ProgressReportId: number, yearlyUpdateCategoryId: yearlyUpdateId, label: dateTerms) => Date | null) = (state: RootState) => {
  return (ProgressReportId: number, yearlyUpdateCategoryId: yearlyUpdateId, label: dateTerms) => {
    const dateBeginning = state[BBGSTATE].bbgProgressReportData[ProgressReportId].dates.beginning
    const startDate = dateBeginning !== null ? new Date(dateBeginning) : null
    const dateEnding = state[BBGSTATE].bbgProgressReportData[ProgressReportId].dates.ending;
    const endDate = dateEnding !== null ? new Date(dateEnding) : null
    const nextStart = endDate !== null ? new Date(endDate) : null
    endDate !== null && nextStart.setDate(endDate.getDate() + 1)

    const nextDateEnd = state[BBGSTATE].bbgProgressReportData[ProgressReportId].dates.nextEndDate
    const nextEnd = nextDateEnd !== null ? new Date(state[BBGSTATE].bbgProgressReportData[ProgressReportId].dates.nextEndDate) : null
    const dateObj = {
      [yearlyUpdateId.current]: {
        [dateTerms.beginning]: startDate,
        [dateTerms.ending]: endDate
      },
      [yearlyUpdateId.actual]: {
        [dateTerms.beginning]: startDate,
        [dateTerms.ending]: new Date(state[BUDGETSTATE].progressReportBudgets[ProgressReportId].actualEndDate + "T00:00:00")
      },
      [yearlyUpdateId.projected]: {
        [dateTerms.beginning]: startDate,
        [dateTerms.ending]: endDate
      },
      [yearlyUpdateId.nextReport]: {
        [dateTerms.beginning]: nextStart,
        [dateTerms.ending]: nextEnd
      }
    }
    return dateObj[yearlyUpdateCategoryId][label]
  }
};


export const selectBeginingOrEndingDateString: (
  state: RootState
) => ((ProgressReportId: number, yearlyUpdateCategoryId: yearlyUpdateId, label: dateTerms ) => string) = (state: RootState) => {
  return (ProgressReportId: number, yearlyUpdateCategoryId: yearlyUpdateId, label: dateTerms  ) => {
    const selectedDate = selectBeginingOrEndingDates(state)(ProgressReportId, yearlyUpdateCategoryId, label)
    if(selectedDate === null){
      return "Missing Date"
    } else{
      return selectedDate.toLocaleDateString()
    }
  }
};

export const selectFinalReportDates = (state: RootState): {projectStartDate: string, projectEndDate: string} => {
  const start = state[BBGSTATE].bbgFinalReportData?.dates[dateTerms.beginning];
  const startDateString = start !== null ? new Date(start).toLocaleDateString() : "*";
  const end = state[BBGSTATE].bbgFinalReportData?.dates[dateTerms.ending];
  const endDateString = end !== null ? new Date(end).toLocaleDateString() : "*"
  return (
    {
      projectStartDate:startDateString ,
      projectEndDate: endDateString
    }
  )
};

/////// BBG SELECTORS /////////
export const selectBlackbaudRequestData = (state: RootState): {projectTitle: string, orgName: string, grantNumber: string} => {
  return (
    {
      projectTitle:state[BBGSTATE].bbgRequestRef?.projectTitle || "Loading",
      orgName: state[BBGSTATE].bbgRequestRef?.organization?.name || "Loading",
      grantNumber: state[BBGSTATE].bbgRequestRef?.referenceNumber || "Loading",
    }
  )
};
export const selectBlackbaudRequestRef = (state: RootState) =>
  state[BBGSTATE].bbgRequestRef;

export const selectBlackbaudRequirementData: (
  state: RootState
) => ((reqId: number) => any) = (state: RootState) => {
  return (reqId: number) => {
    return ( state[BBGSTATE].bbgProgressReportData?.[reqId]  )
  }
};

export const selectApplicablePayments: (
  state: RootState
) => ((beginningDate: Date, endingDate: Date) => Payment[]) = (state: RootState) => {
  return (beginningDate: Date, endingDate: Date) => {
    const applicablePayments = state[BBGSTATE].payments.filter( payment => {
      const scheduledPayment = new Date(payment.scheduleDate);
      const afterBeginningDate = scheduledPayment >= beginningDate;
      const beforeEndingDate = endingDate >= scheduledPayment;
      return afterBeginningDate && beforeEndingDate
    })
    return applicablePayments
  };
};

export const selectApplicablePaymentsAmount: (
  state: RootState
) => ((beginningDate: Date, endingDate: Date) => number) = (state: RootState) => {
  return (beginningDate: Date, endingDate: Date) => {
    return selectApplicablePayments(state)(beginningDate, endingDate).reduce( (acc, next) => acc + next.amount, 0)
  };
};

export const selectApplicablePaymentsAmountById: (
  state: RootState
) => ((progressReportId: number, ) => {}) = (state: RootState) => {
  return (progressReportId) => {
    const allDates = selectGetBeginingOrEndingDates(state)(progressReportId)
    const paymentObj = {
      actual: selectApplicablePaymentsAmount(state)(allDates.actual.beginning, allDates.actual.ending) ,
      current:selectApplicablePaymentsAmount(state)(allDates.current.beginning, allDates.current.ending),
      nextReport:selectApplicablePaymentsAmount(state)(allDates.nextReport.beginning, allDates.nextReport.ending),
      projected:selectApplicablePaymentsAmount(state)(allDates.projected.beginning, allDates.projected.ending)
    }
    return paymentObj//selectApplicablePayments(state)(beginningDate, endingDate).reduce( (acc, next) => acc + next.amount, 0)
  };
};

export const selectAllPaymentsAmount = (state: RootState) => {
  return state[BBGSTATE].bbgRequestRef.grantAmount || 0
};

export default bbgDataStateSlice.reducer;

