import { ProgressReportTable } from './requirements-connector-parts/progress-report-table/ProgressReportTable'
import { ReviewBudgetTable } from './requirements-connector-parts/budget-table-review-requirements/ReviewBudgetTable'
import { FinalReportTable } from './requirements-connector-parts/final-report-table/FinalReportTable'
import { BudgetNarrative } from './requirements-connector-parts/budget-narrative/BudgetNarrative'

import React from 'react'
import Alert from '@mui/material/Alert'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from '@mui/material/Typography';
import {
    setNarrativeOpen,
    selectNarrativeOpen,
} from '../Common/stateSlices/appStateSlice';
import {
    selectCurrentProgressReport,
    selectProgressReportKeys,
    selectFinalReportId
} from '../Common/stateSlices/budgetStateSlice';
import { selectProgressReportLocked } from '../Common/stateSlices/bbgDataSlice';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { useParams} from "react-router-dom";


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const RequirementTabs = () => {
    const currentRequirement = useAppSelector(selectCurrentProgressReport)
    const progressReportLocked = useAppSelector(selectProgressReportLocked)
    const {  reqType, reqId } = useParams();
    const requirementId = Number(reqId);
    const progressReportKeys: Number[] = useAppSelector(selectProgressReportKeys)
    const finalReportId: number = useAppSelector(selectFinalReportId)
    const finalReportExists: boolean = finalReportId !== 0 && finalReportId !== null;

    const dispatch = useAppDispatch();
    const [tabValue, setTabValue] = React.useState(requirementId);

    const narrativeOpen = useAppSelector(selectNarrativeOpen);
    
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }

    const handleCloseNarrative = () => {
        dispatch(setNarrativeOpen(false))
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Original Budget" value={0} />
                    {progressReportKeys.map( 
                        ( progressReportId, index) => 
                        <Tab 
                            value={Number(progressReportId)}
                            key={progressReportId.toString()} 
                                label={`Progress Report ${index + 1} ${Number(progressReportId)  === currentRequirement ? " (Current)" : ""}`}
                        />
                    )}
                    {
                    finalReportExists &&
                        <Tab
                            value={finalReportId}

                            label={`Final Report ${reqType === "final" ? " (Current)" : ""}`}
                        /> 
                    }
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <ReviewBudgetTable />
            </TabPanel>
            {
                progressReportKeys.map((progressReportId, index) => {
                    const isCurrent = progressReportId === currentRequirement;
                    const isLocked = progressReportLocked(Number(progressReportId));
                    console.log(isLocked)
                    const isDisabled = !isCurrent || isLocked;
                    return (
                        <TabPanel key={progressReportId.toString()} value={tabValue} index={Number(progressReportId)}>
                            { isCurrent && isLocked 
                                ?<Alert severity="info" >This progress report has been submitted and the budget has been locked. Please reach out to your program officer if changes need to be made.  </Alert>
                                : null
                            }
                            <ProgressReportTable 
                                progressReportId={progressReportId} 
                                disabled={isDisabled}
                            />

                            <br/>
                            <Dialog
                                fullWidth={true}
                                maxWidth={false}
                                open={narrativeOpen}
                                onClose={handleCloseNarrative}
                            >
                                <DialogContent sx={{ paddingBottom: 0 }}>
                                    <DialogContentText component="div">
                                        <BudgetNarrative progressReportId={Number(progressReportId)} />
                                        {/* <FaqHelp budgetType={BudgetType}/> */}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ paddingTop: 0, }}>
                                    <Button id="narrativeClose" variant="contained" onClick={handleCloseNarrative}>Close</Button>
                                </DialogActions>
                            </Dialog>
                    </TabPanel>)
                }
                )
            }
            {
                finalReportExists 
                    ?
                        <TabPanel value={tabValue} index={finalReportId}>
                            <FinalReportTable disabled={reqType !== "final"} />
                        </TabPanel>
                    : null
            }

        </Box>
    );
}